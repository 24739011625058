.btn {
    white-space: nowrap!important;
    display: inline-block !important;
    font-weight: 400!important;
    line-height: 1.5!important;
    text-align: center!important;
    text-decoration: none!important;
    vertical-align: middle!important;
    cursor: pointer!important;
    -webkit-user-select: none!important;
    -ms-user-select: none!important;
    user-select: none!important;
    background-color: transparent;
    border: 1px solid transparent!important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
    background: linen;
    font-size: 16px!important;
    padding: 5px 10px!important;
    border-radius: 10px!important;
    background-color: #5ad6fc;
    color: white!important;
    margin: 0 10px 0 0!important;
}
.dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.dropdown-menu.show {
    display: block;
}
.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.show.dropdown{
    position: initial;
}


.btn-success:hover {
    color: #fff;
    background-color: #5ad6fc;
    border-color: inherit;
}