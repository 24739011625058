body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  background-color: #e2e1e1e3 !important;

  overflow: auto;
  height: 110vh;
}

body>div>.login {
  overflow: hidden !important;
  height: 100vh !important;
}

html,
body {
  height: fit-content;
  /*
  overflow: hidden;
  min-height: 100vh;
  */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'GucciSans-Bold';
  src: url('./resources/fonts/GucciSans-Bold.otf');
  /* IE9 Compat Modes */
}

@font-face {
  font-family: 'GucciSans-Book';
  src: url('./resources/fonts/GucciSans-Book.otf');
  /* IE9 Compat Modes */
}

@media (max-width: 1000px) {
  body {
    overflow: hidden !important;
    height: auto !important;
  }
}